import RouterProvider from 'pages/routerProvider'
import React from 'react'

const App = () => {
  return (
    <>
      <RouterProvider />
    </>
  )
}

export default App
